<!--  this component support lazyloading and only accept one single event-id value-->
<script>
import "promise-polyfill/src/polyfill";
import constants from "../../common/constants";
import Base from "../Base.vue";

export default {
	name: "TemplatedLeaderboard",
	mixins: [Base],

	props: {
		title: {
			type: String,
			default: "Top Fundraisers",
		},
		showDonate: {
			type: Boolean,
			default: false,
		},
		showEventName: {
			type: Boolean,
			default: false,
		},
		pageSortBy: {
			type: String,
			default: "",
			validator(value) {
				const isValid = [
					"",
					constants.FUNDRAISING_PAGE_SORT_BY_TOTAL_STEP,
					constants.FUNDRAISING_PAGE_SORT_BY_TOTAL_DISTANCE,
					constants.FUNDRAISING_PAGE_SORT_BY_TOTAL_TIME,
					constants.FUNDRAISING_PAGE_SORT_BY_TOTAL_ENERGY,
					constants.FUNDRAISING_PAGE_SORT_BY_TEAM_TOTAL,
					constants.FUNDRAISING_PAGE_SORT_BY_TOTAL,
				].includes(value);
				return isValid;
			},
		},
		pageSortOrder: {
			type: String,
			default: "desc",
		},
		pageRegionCode: {
			type: String,
			default: "",
		},
		widgetTemplate: {
			type: String,
			default: "",
		},
		showTitle: {
			type: Boolean,
			default: false,
		},
		isDemoMode: {
			type: Boolean,
			default: true,
		},
		settings: {
			type: Object,
			default: () => {
				return {
					includeSubEvents : true,
					loadMoreOption : 'fixed'
				};
			}
		},
        excludeDisabledFundraisingPages: {
            type: Boolean,
            default: false,
        },
	},

	data: function () {
		return {
			loading: true,
			showLoadMore: true,
			resultsToPerBatch: 10,
			currentPage: 1,
			morePages: true,
			driverButtonColour: "",
			driverTextColour: "",
      		maxSize: 200,
			disableInfiniteScroll: false
		};
	},
	watch: {
		morePages(val) {
			if (!val)
				this.showLoadMore = false;
		}
	},
 
	mounted() {
		this.disableInfiniteScroll = this.settings.loadMoreOption !== constants.LOAD_MORE_OPTION_INFINITE;
		this.showLoadMore = this.settings.loadMoreOption === constants.LOAD_MORE_OPTION_LOADMORE;
	},

	methods: {	
		isMoreDataAvailable(response) {					
			return (
				response.ResultsReturned == this.size &&
				response.PageNumber <= response.TotalPages &&
				(response.PageNumber + 1) <= response.TotalPages
			);
		},
	},
};
</script>