<template lang="html">
  <div id="vue-gf-fundraiser-event-stats">
    <div class="container">
      <div class="row">
        <!-- corporates -->
        <div class="col-4">
          <div class="row">
            <div class="col-6 gf-fres-corp-img" />
            <div class="col-6">
              <div class="row col h1 gf-fres-corp-count">
                {{ eventIds.length }}
                <!-- TODO: Actualy get a propert corporate count not a length of events -->
              </div>
              <div class="row col h4 gf-fres-corp-title">
                {{ settings.corporatePagesTitle }}
              </div>
            </div>
          </div>
        </div>
        <!-- Individuals -->
        <div class="col-4">
          <div class="row">
            <div class="col-6 gf-fres-ind-img" />
            <div class="col-6">
              <div class="row col h1 gf-fres-ind-count">
                {{ individualPages }}
              </div>
              <div class="row col h4 gf-fres-ind-title">
                {{ settings.individualPagesTitle }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-4">
          <!-- teams -->
          <div class="row">
            <div class="col-6 gf-fres-team-img" />
            <div class="col-6">
              <div class="row col h1 gf-fres-team-count">
                {{ teamPages }}
              </div>
              <div class="row col h4 gf-fres-team-title">
                {{ settings.teamPagesTitle }}
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="
            settings.subEventsTitle &&
              settings.subEventsTitle !== ''
          "
          class="col-4"
        >
          <!-- sub events -->
          <div class="row">
            <div class="col-6 gf-fres-subevent-img" />
            <div class="col-6">
              <div class="row col h1 gf-fres-subevent-count">
                {{ subEvents }}
              </div>
              <div class="row col h4 gf-fres-subevent-title">
                {{ settings.subEventsTitle }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import "promise-polyfill/src/polyfill";
import Base from './Base.vue';

export default {
    name: "FundraiserEventStats",

    mixins: [Base],

    props: {
        settings: {
            type: Object,
            default: () => {
                return {
                    individualPagesTitle: "Participants",
                    teamPagesTitle: "Teams",
                    corporatePagesTitle: "Corporates",
                    subEventsTitle: "",
                    includeEventInSubEventCount: "false"
                };
            }
        },
        excludeDisabledFundraisingPages: {
          type: Boolean,
          default: false,
       }
    },

    data: function() {
        return {
            teamPages: 0,
            individualPages: 0,
            corporates: 0,
            subEvents: 0
        };
    },

    mounted: function() {
        this.populateStats();
    },

    methods: {
        async populateStats() {
            var fundraiserTeamPagesPromises = [];
            var fundraiserIndividualPagesPromises = [];
            this.eventIds.forEach(element => {
                fundraiserTeamPagesPromises.push(
                    this.getPages(element, 1, "t")
                );
                fundraiserIndividualPagesPromises.push(
                    this.getPages(element, 1, "s")
                );
            });

            await this.updateTeamPagesCount(fundraiserTeamPagesPromises);

            await this.updateIndividualPagseCount(
                fundraiserIndividualPagesPromises
            );
            if (this.settings.subEventsTitle !== "") {
                this.subEvents = await this.getSubEventsCount(this.eventIds[0]);
            }
        },

        getPages(eventId, size, pageType) {
            var getPagesUrl = `https://${this.apiDomain}/v1/pages/search?eventcampaignid=${eventId}&pagesize=${size}&pageindex=1&pagetype=${pageType}&tags=${this.tags}&excludepageswithnofundraising=${this.excludeDisabledFundraisingPages}`;
            return axios.get(getPagesUrl);
        },

        async getSubEventsCount(eventId) {
            var getEventsUrl = `https://${this.apiDomain}/v1/events/search?eventcampaignid=${eventId}&pagesize=1&pageindex=1&includesubevents=true`;
            var result = (await axios.get(getEventsUrl)).data;
            return this.settings.includeEventInSubEventCount
                ? result.TotalPages
                : result.TotalPages - 1;
        },

        async updateTeamPagesCount(teamPagePromises) {
            await axios.all(teamPagePromises).then(res => {
                res.forEach(response => {
                    if (response.status === 200) {
                        this.teamPages += response.data.TotalPages;
                    }
                });
            });
        },

        async updateIndividualPagseCount(individualPagePromises) {
            await axios.all(individualPagePromises).then(res => {
                res.forEach(response => {
                    if (response.status === 200) {
                        this.individualPages += response.data.TotalPages;
                    }
                });
            });
        }
    }
};
</script>
